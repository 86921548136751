<template>
  <div>
    <div class="container">
      <el-form ref="form" :model="queryInfo" label-width="120px">
        <el-card>
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="排期状态:" prop="">
                <el-radio-group v-model="queryInfo.questionnaireId" @change="changeSearchangeSearch">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="1">已排期 </el-radio-button>
                  <el-radio-button label="2">未排期 </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-card>
      </el-form>
      <el-card style="margin-top: 20px">
        <div class="input-with">
          <el-input
            placeholder="请输入用户名称"
            v-model="queryInfo.realName"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="changeSearchangeSearch">搜索</el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中..."
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" prop="id"></el-table-column>

          <el-table-column label="预约时间" sortable prop="createTime" align="center">
            <template v-slot="{ row }">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template v-slot="{ row }">
              <span>{{ row.realName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="职位" prop="position" align="center"></el-table-column>
          <el-table-column label="手机号" prop="companyFullName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.companyFullName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作人" prop="updateName" align="center"></el-table-column>
          <el-table-column label="操作时间" prop="updateTime" align="center"></el-table-column>

          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="260px"
          >
            <template slot-scope="scope">

              <el-button v-if="scope.row.isSchedule" style="color: #999999" type="text"
              >排期成功
              </el-button>

              <el-button v-else style="color: cornflowerblue" type="text" @click="torecord(scope.row)"
              >排期
              </el-button>

            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { diagnosticAppointment,updateIsSchedule } from '@/api/diagnosis'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  isSchedule: null,
  order:'desc',
  pageNum: 1,
  pageSize: 10,
  realName:null
})
export default {
  name: 'Diagnosisrecord',
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await diagnosticAppointment(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    //进入诊断详情
  async  torecord(row) {
      const res= await updateIsSchedule({id:row.id})
      if(res.data.resultCode==200){
        this.$message.success('排期成功！')
        this.search()
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }

  }
}
</script>

<style lang="less" scoped>
.container {
  height: 80vh;
  overflow: scroll;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>
